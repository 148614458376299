<template>
  <el-main ref="main">
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品名称：">
        <el-input size="small" v-model="goods_name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="商品编码：">
        <el-input size="small" v-model="goods_coding" placeholder="请输入商品编码"></el-input>
      </el-form-item>
      <el-form-item label="商品分类：">
        <el-cascader size="small" clearable v-model="classify_id" :options="goodsClass" @change="typeChange"></el-cascader>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="getList" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="goodsList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="商品信息" width="400" align="center">
        <template v-slot="{ row }">
          <div class="goodsInfo">
            <el-image :src="row.goods_picture"></el-image>
            <div class="goods_name">{{ row.goods_name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="goods_price" label="商品价格" align="center"></el-table-column>
      <el-table-column prop="goods_prime_cost" label="商品原价" align="center"></el-table-column>
      <el-table-column prop="goods_stock" label="SKU库存" align="center"></el-table-column>
      <el-table-column label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.is_set ? '已设置' : '未设置' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="set(row)" type="text" size="small">设置</el-button>
          <el-button v-if="row.is_set" @click="cancel(row)" type="text" size="small">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="分销设置" :visible.sync="dislogSet" width="650px">
      <div class="set-info">
        <div class="goods-info">
          <el-image :src="currentGoodsInfo.goods_picture"></el-image>
          <span>{{ currentGoodsInfo.goods_name }}</span>
        </div>
        <div v-if="currentGoodsInfo.is_spec" class="batch">
          <el-form>
            <el-form-item label="商品佣金：">
              <el-radio-group v-model="type">
                <el-radio :label="1">按金额（元）</el-radio>
                <el-radio :label="2">按比例（%）</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品分类：">{{ classify_name }}</el-form-item>
          </el-form>
          <table>
            <thead>
              <tr>
                <th>规格</th>
                <th>价格（元）</th>
                <th>{{ type == 1 ? '分销佣金（元）' : '分销比例（%）' }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in groupInfo" :key="index">
                <td>{{ item.title }}</td>
                <td>{{ item.price }}</td>
                <td>
                  <el-input v-model="item.value" size="small"></el-input>
                </td>
              </tr>
            </tbody>
          </table>
          <el-form>
            <el-form-item style="margin: 0" label="批量输入：">
              <el-input style="width: 160px; margin-right: 15px" v-model="batch_distribution_commission" size="small" :placeholder="type == 1 ? '佣金（元）' : '比例（%）'"></el-input>
              <el-button @click="batchPut" type="primary" size="small">确定</el-button>
            </el-form-item>
            <el-form-item>注：分销佣金为此商品卖出后，给分销员的总金额</el-form-item>
          </el-form>
        </div>
        <div v-else class="alone">
          <el-form label-width="90px">
            <el-form-item label="商品价格：">
              {{ currentGoodsInfo.goods_price }}
            </el-form-item>
            <el-form-item label="商品佣金：">
              <el-radio-group v-model="type">
                <el-radio :label="1">按金额（元）</el-radio>
                <el-radio :label="2">按比例（%）</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商品分类：">{{ classify_name }}</el-form-item>
            <el-form-item label="分销佣金：">
              <el-input v-model="distribution_commission"></el-input>
            </el-form-item>
            <el-form-item>注：分销佣金为此商品卖出后，给分销员的总金额</el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogSet = !1">取 消</el-button>
        <el-button type="primary" @click="comefromSet">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      dislogSet: !1,
      goodsList: [],
      page: 1,
      rows: 10,
      total_number: 0,
      goods_name: '',
      goods_coding: '',
      classify_id: [],
      classify_ids: '',
      currentGoodsInfo: {},
      groupInfo: [],
      distribution_commission: '',
      is_show: 0,
      picture: '',
      type: 1,
      classify_name: '',
      batch_distribution_commission: '',
    };
  },
  computed: {
    goodsClass() {
      let goodsTypeList = this.$store.getters.goodsTypeList;
      return goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    this.getList();
  },
  methods: {
    batchPut() {
      this.groupInfo.map(item => {
        if (this.batch_distribution_commission) {
          item.value = this.batch_distribution_commission;
        }
      });
    },
    searchClear() {
      this.goods_name = '';
      this.goods_coding = '';
      this.page = 1;
      this.classify_id = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getList();
      } else {
        this.page = val;
        this.getList();
      }
    },
    getList() {
      let classify_id = this.classify_id;
      if (classify_id.length) {
        if (classify_id.length > 1) {
          classify_id = classify_id[1];
        } else {
          classify_id = classify_id[0];
        }
      }
      this.$axios
        .post('/Beauty' + this.$api.distribution.goodsList, {
          status: 1,
          page: this.page,
          rows: this.rows,
          goods_name: this.goods_name,
          goods_coding: this.goods_coding,
          classify_id: classify_id,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.goodsList = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    set(row) {
      this.distribution_commission = '';
      this.batch_distribution_commission = '';
      this.$axios
        .post('/Beauty' + this.$api.distribution.goodsDetail, {
          goods_id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            let goodsClass = this.goodsClass;
            let classify_name = '';
            this.dislogSet = !0;
            this.currentGoodsInfo = row;
            this.type = res.result.type || 1;
            this.classify_ids = res.result.classify_id;
            let classify_ids_arr = res.result.classify_id.split(',').map(Number);
            for (let i in goodsClass) {
              for (let y in classify_ids_arr) {
                if (goodsClass[i].id == classify_ids_arr[y]) {
                  if (goodsClass[i].children == undefined) classify_name += goodsClass[i].name + '、';
                }
                if (goodsClass[i].children) {
                  goodsClass[i].children.map(item => {
                    if (item.id == classify_ids_arr[y]) {
                      classify_name += item.name + '、';
                    }
                  });
                }
              }
            }
            this.classify_name = classify_name.slice(0, classify_name.length - 1);
            if (row.is_spec) {
              for (let i in res.result.setting) {
                res.result.setting[i].value = res.result.setting[i].value || '';
              }
              this.groupInfo = res.result.setting;
            } else {
              if (row.is_set) {
                this.distribution_commission = res.result.setting.value;
              }
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    cancel(row) {
      this.$confirm('确认是否取消该商品分销？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post('/Beauty' + this.$api.distribution.goodsCancel, {
            goods_id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_set = 0;
              this.$message.success('已取消');
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    comefromSet() {
      if (this.currentGoodsInfo.is_spec) {
        for (let i in this.groupInfo) {
          if (!this.groupInfo[i].value) return this.$message.warning('请填写完所有规格分销佣金');
        }
        if (this.type == 1) {
          for (let i in this.groupInfo) {
            if (Number(this.groupInfo[i].value) >= Number(this.groupInfo[i].price)) return this.$message.warning('分销佣金不能大于等于商品价格');
          }
        } else {
          for (let i in this.groupInfo) {
            if (Number(this.groupInfo[i].value) > 100) return this.$message.warning('分销佣金比例不能大于100%');
          }
        }
        this.$axios
          .post('/Beauty' + this.$api.distribution.goodsSet, {
            goods_id: this.currentGoodsInfo.id,
            is_spec: 1,
            type: this.type,
            classify_id: this.classify_ids,
            setting: this.groupInfo,
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.dislogSet = !1;
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        if (!this.distribution_commission) return this.$message.warning('请填写分销佣金');
        if (this.type == 1) {
          if (Number(this.distribution_commission) >= Number(this.currentGoodsInfo.goods_price)) return this.$message.warning('分销佣金不能大于等于商品价格');
        } else {
          if (Number(this.distribution_commission) > 100) return this.$message.warning('分销佣金比例不能大于100%');
        }
        this.$axios
          .post('/Beauty' + this.$api.distribution.goodsSet, {
            goods_id: this.currentGoodsInfo.id,
            is_spec: 0,
            type: this.type,
            classify_id: this.classify_ids,
            setting: {
              price: this.currentGoodsInfo.goods_price,
              value: this.distribution_commission,
            },
          })
          .then(res => {
            if (res.code == 0) {
              this.getList();
              this.dislogSet = !1;
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .goodsInfo {
    display: flex;
    align-items: center;
    .el-image {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    .goods_name {
      width: 340px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .set-info {
    .goods-info {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
    table {
      border: 1px solid #ebeef5;
      width: 100%;
      margin-bottom: 20px;
      thead {
        tr th {
          padding: 10px;
          text-align: center;
          border: 1px solid #cccccc;
          font-weight: bold;
          background: #f8f9fa;
        }
      }
      tbody {
        tr td {
          padding: 10px;
          width: 80px;
          text-align: center;
          border: 1px solid #cccccc;
        }
      }
    }
  }
}
</style>


